<template>
<div @click="snackbar = false">
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <div style="margin-right: 15px;" @click="back">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <v-toolbar-title>TMS Order Task Status</v-toolbar-title>
  </v-app-bar>

  <div style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%; padding: 10px;">

    <div style="padding: 8px;"></div>

    <v-select
      v-model="selected_status"
      :items="status_lists"
      label="Status"
    ></v-select>

    <div style="text-align: center; margin-bottom:27px;">
      <v-btn x-large color="success" dark :disabled="save_status_btn_disable" @click="saveTmsOrderTaskStatus">Save</v-btn>
    </div>
  </div>

  <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" style="font-size: larger;position: absolute;top: -20%;white-space: pre-wrap">
    {{ snackbar_txt }}
  </v-snackbar>

</div>
</template>

<script>
import { get, post } from '@/utils/request.js'
import { EH_MOBILE_GLOBAL, EH_MOBILE_FUN } from '@/helper.js'

export default {
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 0,

      tms_route_id: null,
      tms_order_task_id: null,
      tms_order: {},
      tms_goods: [],
      tms_task: [],

      status_lists: [],

      current_task: null,
      selected_status: null,
      save_status_btn_disable: false
    }
  },
  watch: {
    tab_select (newval, oldval) {
      console.log([newval, oldval])
    },
  },
  methods: {
    getTmsOrderDetail: function (callbck) {
      let url = EH_MOBILE_GLOBAL.get_tms_order_detail + '?tms_route_id=' + this.tms_route_id + '&tms_order_id=' + 'multiple' +
        '&tms_order_task_id=' + this.tms_order_task_id

      get(url).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.tms_order = data.order
        this.tms_goods = data.goods
        this.tms_task = data.tasks

        this.status_lists = Object.values(data.statusLists)

        for (let i in this.tms_task) {
          let task = this.tms_task[i]
          if (task.id == this.tms_order_task_id) {
            this.current_task = task
          }
        }

        if (typeof callbck == 'function') {
          callbck(response)
        }
      })
    },
    back: function () {
      this.$router.go(-1)
    },
    saveTmsOrderTaskStatus () {
      this.doSaveTmsOrderTaskStatus(null, null)
    },
    doSaveTmsOrderTaskStatus (latitude, longitude) {
      let url = EH_MOBILE_GLOBAL.save_tms_order_task_status
      let data = {
        tms_task_id: this.tms_order_task_id,
        staus_id: this.selected_status,
        from: 'multiple',
        latitude: latitude,
        longitude: longitude,
      }
      this.save_status_btn_disable = true

      post(url, EH_MOBILE_FUN.serialize(data)).then((response) => {
        this.save_status_btn_disable = false
        if (response.data.status == 'success') {
          this.back()
          // this.getTmsOrderDetail()
        } else {
          this.sMsg(response.data.reason);
          return
        }
      })
    },
    sMsg(txt) {
      this.snackbar_txt = txt
      this.snackbar = true
      return
    },
  },
  created () {
    this.tms_route_id = this.$route.params.tms_route_id
    this.tms_order_task_id = this.$route.params.tms_order_task_id

    this.getTmsOrderDetail()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
